:root{
    --sidebar-width: 208px;
}

body{
    @apply bg-neutral-100
}

.hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hide-scrollbar::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}


.inset-x-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

.inset-y-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
.inset-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

@tailwind base;
@tailwind components;
@tailwind utilities;