.loaderRing {
    display: inline-flex;
    position: relative;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: calc(30px - 4px - 4px);
      height: calc(30px - 4px - 4px);
      margin: 2px;
      border: 2px solid;
      border-radius: 50%;
      animation: loaderRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #00abd5 transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
  @keyframes loaderRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  